<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Borba consiste em um povoado do subdistrito de Pedras, pertence
                ao distrito de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_aguas_claras' }"
                  >Águas Claras</router-link
                >, que por sua vez faz parte do Município de Mariana/MG.
                Situa-se a aproximadamente 9 km de distância do núcleo urbano de
                Águas Claras, a 47 km do centro de Mariana e a 159 km de Belo
                Horizonte, capital do Estado de Minas Gerais. Borba situa-se a
                leste de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_paracatu' }"
                  >Paracatu de Baixo</router-link
                >, a aproximadamente 4km de seu núcleo urbano. A região
                apresenta relevo montanhoso coberto por mata, grande quantidade
                de afloramentos rochosos e extensões de pastagem. O Rio Gualaxo
                do Norte atravessa o território, em meio aos terrenos produtivos
                rurais.
              </p>
              <p>
                A comunidade é formada por sítios e fazendas com ênfase na
                criação de bovinos leiteiros e produção de leite. Nas terras são
                cultivados roçados e hortaliças, além de frutíferas que garantem
                a alimentação das famílias. As casas seguem o padrão das
                construções típicas rurais da região, com planta quadrada,
                telhados cobertos por telhas cerâmicas dispostos em duas ou
                quatro águas, poucos vãos de portas e janelas, de dimensões
                pequenas, fechados em sua maioria com folhas cegas de madeira,
                havendo também esquadrias metálicas. Geralmente estão
                implantadas na área de planície, assim como as edificações de
                uso rural e os cultivos, que ocupam a extensão de baixada,
                próximo ao rio.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img width="100%" src="../../assets/sobre_territorio_borba_01.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Placa de sinalização de acesso para o povoado de Borba. Foto: Flora
            Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-start"
        >
          <img width="100%" src="../../assets/sobre_territorio_borba_02.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Paisagem com relevo montanhoso coberto por pastagem. Em primeiro
            plano, placa antiga e desgastada pelo tempo, indicando o nome do
            povoado. Foto: Flora Passos, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Entre as construções acessórias de uso rural destacam-se os
                currais, presentes nos terrenos de criação de bovinos. Também
                são comuns os galinheiros, chiqueiros, paióis, coberta de lenha
                e do próprio fogão a lenha.
              </p>
              <p>
                Majoritariamente foram construídas utilizando-se materiais de
                construção coletados na região como madeira, bambu e barro.
                Possuem aspecto simples, geralmente com planta retangular e
                telhado de uma ou duas águas. Vários mantiveram o uso de telhas
                cerâmicas e parte foi adotando as telhas de fibrocimento.
              </p>
              <p>
                Não havia no povoado um núcleo urbanizado com presença de templo
                religioso, escola, estabelecimentos comerciais, etc. Os
                moradores de Borba frequentavam tanto a comunidade de Pedras
                quanto de Paracatu de Baixo, se beneficiando do comércio de
                Paracatu, havendo ainda entre eles relações de amizade e de
                parentesco. Era comum também o comércio de produtos cultivados e
                de animais entre as comunidades. A produção de leite por vezes
                era comercializada em Paracatu, bem como abastecia a linha de
                coleta da Associação dos produtores de leite de Águas Claras.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Os encontros, socialização e atividades religiosas da população
                ocorrem na Capela de Santo Antônio, em Pedras. Destacam-se entre
                as manifestações tradicionais a Folia de Reis de Pedras, a Festa
                de São Sebastião, Festa de Santo Antônio, Padroeiro de Pedras,
                Festa de Nossa Senhora Aparecida e Festa de Nossa Senhora das
                Graças. Além dos festejos comuns entre a comunidade de Borba e
                Pedras, os moradores de Borba frequentavam as manifestações
                tradicionais de Paracatu de Baixo, de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_campinas' }"
                  >Campinas</router-link
                >
                e de Águas Claras, conforme o
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                  >calendário litúrgico das comunidades</router-link
                >.
              </p>
              <p>
                As cachoeiras e o rio também eram locais de diversão e
                socialização antes do rompimento da barragem, ficando
                comprometidos após o desastre. No Rio Gualaxo do Norte os
                moradores além de se banhar, realizavam atividade de pesca para
                lazer e consumo. A “Cachoeira do Ó” era um local de diversão
                junto à natureza, situado em
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_campinas' }"
                  >Campinas</router-link
                >, subdistrito de Águas Claras.
              </p>
              <p style="text-align: center; font-size: 0.6em">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup> </strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img width="100%" src="../../assets/sobre_territorio_borba_03.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Vista de uma casa situada em terreno de Borba, na área plana, com
            árvore no quintal e plantas de jardim. Foto: Flora Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center alig-center flex-column"
        >
          <v-img
            src="../../assets/sobre_territorio_borba_04.png"
            max-width="100%"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em">
            Vista interna do curral de um terreno em Borba. Foto: Vistoria feita
            pela assessoria técnica da Cáritas, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center alig-center flex-column"
        >
          <v-img
            src="../../assets/sobre_territorio_borba_05.png"
            max-width="100%"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em">
            Fogão a lenha com forno embutido em uma casa de Borba. Foto: Flora
            Passos, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center alig-center flex-column"
        >
          <v-img
            src="../../assets/sobre_territorio_borba_06.png"
            max-width="100%"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em">
            Encontro do córrego com o Rio Gualaxo do Norte. Foto: Vistoria feita
            pela assessoria técnica da Cáritas, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center alig-center flex-column"
        >
          <v-img
            src="../../assets/sobre_territorio_borba_07.png"
            max-width="100%"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em">
            Cachoeira do Ó. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p class="ma-0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

#app
  > div
  > main
  > div
  > div
  > div.container
  > section
  > div
  > div
  > div.v-data-table.theme--light
  > div
  > table
  > thead
  > tr
  > th {
  color: black;
  font-size: 0.6em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
